import store from "../store";

export default [
  {
    path: "/",
    meta: { requireAuth: true },
    component: () => import(`@/views/DashboardView.vue`),
    children: [
      // error pages

      {
        path: "/404",
        name: "404",
        meta: { conditionalAuth: true },
        component: () => import(`@/components/error/404.vue`),
      },

      // #province dashboard
      {
        path: "/",
        name: "Home",
        meta: { requireAuth: true },
        beforeEnter(to, from, next) {
          let link = JSON.parse(localStorage.getItem("userInfo"))
            .dashBoardBranchUser;
          next(link);
        },
      },
      {
        path: "/MinistryDashboard",
        name: "MinistryDashboard",
        meta: { requireAuth: true, ministry: true },
        component: () => import(`@/modules/dashboard/Province.vue`),
      },
      {
        path: "/admin_add_section/province",
        name: "AddProvinceSection",
        meta: { requireAuth: true },
        component: () => import(`@/modules/province/List.vue`),
      },
      {
        path: "/DashboardNotice",
        name: "DashboardNotice",
        meta: { requireAuth: true },
        component: () => import(`@/modules/notice/List.vue`),
        },
        {
            path: "/DashboardInquiry",
            name: "DashboardInquiry",
            meta: { requireAuth: true },
            component: () => import(`@/modules/inquiry/List.vue`),
        },

      // #Division
      {
        path: "province/:provinceID/division/",
        name: "DivisionList",
        meta: { requireAuth: true },
        component: () => import(`@/modules/dashboard/Division.vue`),
      },
      {
        path: "/sub-branch/list/:id",
        name: "SubBranchList",
        meta: { requireAuth: true },
        component: () => import(`@/modules/sub-branch/List.vue`),
      },
      {
        path: "/admin_add_section/division",
        name: "AddDivisionSection",
        meta: { requireAuth: true },
        component: () => import(`@/modules/division/List.vue`),
      },

      // #Branch

      {
        path: "/ProvinceDashboard",
        name: "ProvinceDashboard",
        meta: { requireAuth: true },
        component: () => import(`@/modules/dashboard/ProvinceDashboard.vue`),
      },
      {
        path: "/DivisionDashboard",
        name: "DivisionDashboard",
        meta: { requireAuth: true },
        component: () => import(`@/modules/dashboard/ProvinceDashboard.vue`),
      },

      {
        path: "/Branch",
        name: "Branch",
        meta: { requireAuth: true },
        component: () => import(`@/modules/branch/BranchDashboard.vue`),
      },
      {
        path: "/admin_add_section/branch",
        name: "AddBranchSection",
        meta: { requireAuth: true },
        component: () => import(`@/modules/branch/List.vue`),
        },

      {
        path: "province/:provinceID/division/:divisionID/branch/list",
        name: "BranchList",
        meta: { requireAuth: true },
        component: () => import(`@/modules/dashboard/Branch.vue`),
      },

      // #Users

      {
        path: "/user-profile",
        name: "UserProfile",
        meta: { conditionalAuth: true },
        component: () => import(`@/modules/user/UserProfile.vue`),
      },
      {
        path: "/admin-user/list",
        name: "AdminUserList",
        meta: { requireAuth: true },
        component: () => import(`@/modules/admin-user/List.vue`),
      },

      {
        path: "/user/list/:id",
        name: "UserList",
        meta: { requireAuth: true },
        component: () => import(`@/modules/user/List.vue`),
      },

      // #Immigration
      {
        path: "/Tourist-ArrivalDashboard",
        name: "Tourist-ArrivalDashboard",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/immigration/Dashboard/ImmigrationDashboard.vue`),
      },

      {
        path: "/Tourist-ArrivalCustomer",
        name: "Tourist-ArrivalCustomer",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/immigration/Customer/CustomerList.vue`),
      },
      {
        path: "/Tourist-ArrivalHistoryList",
        name: "Tourist-ArrivalHistoryList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/immigration/History/HistoryList.vue`),
      },
      {
        path: "/immigration",
        name: "ApiTourist-Arrival",
        meta: { requireAuth: true },
        component: () => import(`@/modules/immigration/List.vue`),
      },

      // #Mountaineering
      {
        path: "/MountaineeringDashboard",
        name: "MountaineeringDashboard",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/Mountain/Dashboard/MountainDashboard.vue`),
      },
      {
        path: "/MountaineeringHistoryList",
        name: "MountaineeringHistoryList",
        meta: { requireAuth: true },
        component: () => import(`@/modules/Mountain/History/HistoryList.vue`),
      },
      {
        path: "/MountaineeringSettings",
        name: "MountaineeringSettings",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/Mountain/Settings/Dashboard/Settings.vue`),
      },
      {
        path: "/MountainColumns",
        name: "MountainColumns",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/Mountain/Settings/Mountains/MountainList.vue`),
      },
      {
        path: "/MountainSetup",
        name: "MountainSetup",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/Mountain/Settings/MountainSetup/MountainList.vue`),
      },
      {
        path: "/mountaineeringCustomer",
        name: "mountaineeringCustomer",
        meta: { requireAuth: true },
        component: () => import(`@/modules/Mountain/Customer/CustomerList.vue`),
      },
      {
        path: "/mountaineeringPendingSummiters",
        name: "mountaineeringPendingSummiters",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/Mountain/PendingSummiters/CustomerList.vue`),
      },
      // #Aviation
      {
        path: "/AviationDashboard",
        name: "AviationDashboard",
        meta: { requireAuth: true },
        component: () => import(`@/modules/aviation/AviationDashboard.vue`),
      },
      {
        path: "/AviationSettings",
        name: "AviationSettings",
        meta: { requireAuth: true },
        component: () => import(`@/modules/aviation/AviationSettings.vue`),
      },
      {
        path: "/AirDetailList",
        name: "AirDetailList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/aviation/airDetail/AirDetailList.vue`),
      },
      {
        path: "/AirDestinationList",
        name: "AirDestinationList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/aviation/airDestination/AirDestinationList.vue`),
      },
      {
        path: "/AirServices",
        name: "AirServicesList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/aviation/airServices/AirServicesList.vue`),
      },
      {
        path: "/AviationCustomerList",
        name: "AviationCustomerList",
        meta: { requireAuth: true },
        component: () => import(`@/modules/aviation/customer/CustomerList.vue`),
        },
        {
            path: "/CaanRegristrationForm",
            name: "CAANRegistrationForm",
            meta: { requireAuth: true },
            component: () => import(`@/modules/airport/Registration/List.vue`),
        },
        {
            path: "/CaanFilterPage",
            name: "CAANFilterPage",
            meta: { requireAuth: true },
            component: () => import(`@/modules/airport/Filter/Dashboard.vue`),
        },
        {
            path: "/Filter/Airport",
            name: "AirportFilterPage",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/airport/Filter/Airport/List.vue`),
        },
        {
            path: "/Filter/Ariline",
            name: "AirlineFilterPage",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/airport/Filter/Airline/List.vue`),
        },
        {
            path: "/CaanReportPage",
            name: "CAANReport",
            meta: { requireAuth: true },
            component: () => import(`@/modules/airport/Report/Dashboard.vue`),
        },
        {
            path: "/Report/TotalTouristVisit",
            name: "TotalTouristVisitReport",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/airport/Report/TotalTouristVisit/Dashboard.vue`),
        },
        {
            path: "/Report/TouristVisitCountryWise",
            name: "TouristVisitCountryWiseReport",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/airport/Report/TouristVisitCountryWise/Dashboard.vue`),
        },
        {
            path: "/Report/OccupiedSeats",
            name: "OccupiedSeatsReport",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/airport/Report/OccupiedSeats/Dashboard.vue`),
        },
        {
            path: "/Report/TotalTouristVisit/Airport",
            name: "TotalTouristVisitAirportFilterPage",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/airport/Report/TotalTouristVisit/Airport/List.vue`),
        },
        {
            path: "/Report/TotalTouristVisit/Airline",
            name: "TotalTouristVisitAirlineFilterPage",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/airport/Report/TotalTouristVisit/Airline/List.vue`),
        },
        {
            path: "/Report/TouristVisitCountryWise/Airport",
            name: "TouristVisitCountryWiseAirportFilterPage",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/airport/Report/TouristVisitCountryWise/Airport/List.vue`),
        },
        {
            path: "/Report/TouristVisitCountryWise/Airline",
            name: "TouristVisitCountryWiseAirlineFilterPage",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/airport/Report/TouristVisitCountryWise/Airline/List.vue`),
        },

      {
        path: "/AviationPackageList",
        name: "AviationPackageList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/aviation/airPackages/AirPackagesList.vue`),
        },
        {
            path: "/AviationEmployeeList",
            name: "AviationEmployeeList",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/aviation/EmployeeInformation/List.vue`),
        },
      {
        path: "/AviationPackageSetting",
        name: "AviationPackageSetting",
        meta: { requireAuth: true },
        component: () => import(`@/modules/aviation/Package/List.vue`),
      },

      // #Helicopter
      {
        path: "/HelicopterDashboard",
        name: "HelicopterDashboard",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/helicopter/Dashboard/HelicopterDashboard.vue`),
      },
      {
        path: "/HelicopterCustomerList",
        name: "HelicopterCustomerList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/helicopter/Customer/CustomerList.vue`),
      },
      {
        path: "/HelicopterService",
        name: "HelicopterService",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/helicopter/Settings/Service/List.vue`),
      },
      {
        path: "/HelicopterSettings",
        name: "HelicopterSettings",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/helicopter/Settings/Dashboard/HelicopterSettings.vue`
          ),
      },
      {
        path: "/HelicopterAirDestination",
        name: "HelicopterAirDestination",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/helicopter/Settings/AirDestination/List.vue`),
      },
      {
        path: "/HelicopterDetail",
        name: "HelicopterDetail",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/helicopter/Settings/Details/List.vue`),
      },
      {
        path: "/HelicopterPackageSetting",
        name: "HelicopterPackageSetting",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/helicopter/Settings/Package/List.vue`),
      },

      // #Hotel setting
      {
        path: "/hotel",
        name: "HotelCustomerInformation",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/hotel/HotelCustomerInformation/List.vue`),
      },
      {
        path: "/hotelEmployee",
        name: "HotelEmployeeInformation",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/hotel/HotelEmployeeInformation/List.vue`),
      },
      {
        path: "/hotel/create",
        name: "HotelCreate",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/hotel/HotelCustomerInformation/create.vue`),
      },
      {
        path: "/hotel/edit",
        name: "HotelEdit",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/hotel/HotelCustomerInformation/edit.vue`),
      },
      {
        path: "/hotel/details",
        name: "HotelDetail",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/hotel/HotelCustomerInformation/Details.vue`),
      },
      {
        path: "/hotel/hotelDetails/:hotelID",
        name: "Details",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/hotel/HotelCustomerInformation/HotelDetail.vue`),
      },
      {
        path: "/IndividualHotel",
        name: "Individual",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/hotel/Dashboard/IndividualHotel.vue`),
      },
      {
        path: "/hotel/room",
        name: "HotelRoom",
        meta: { requireAuth: true },
        component: () => import(`@/modules/hotel/HotelRoom/List.vue`),
      },
      {
        path: "/HotelSettings",
        name: "HotelRoomDashboard",
        meta: { requireAuth: true },
        component: () => import(`@/modules/hotel/HotelRoom/RoomDashboard.vue`),
      },
      {
        path: "/hotel/room/setting",
        name: "HotelSetting",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/hotel/HotelRoom/HotelRoomSetting/List.vue`),
      },
      {
        path: "/hotel/package/setting",
        name: "HotelPackageSetting",
        meta: { requireAuth: true },
        component: () => import(`@/modules/hotel/HotelRoom/Package/List.vue`),
      },
      {
        path: "/hotel/service/setting",
        name: "HotelServiceSetting",
        meta: { requireAuth: true },
        component: () => import(`@/modules/hotel/HotelRoom/Service/List.vue`),
      },
      {
        path: "/hotel/category/setting",
        name: "HotelCategorySetting",
        meta: { requireAuth: true },
        component: () => import(`@/modules/hotel/HotelCategory/List.vue`),
      },
      {
        path: "/availableRoom",
        name: "HotelAvailableRoom",
        meta: { requireAuth: true },
        component: () => import(`@/modules/hotel/HotelRoom/Available.vue`),
        },

        {
            path: "/subBranch",
            name: "HotelSubBranch",
            meta: { requireAuth: true },
            component: () => import(`@/modules/hotel/HotelRoom/SubBranch/List.vue`),
        },

      {
        path: "/HotelDashboard",
        name: "HotelDashboard",
        meta: { requireAuth: true },
        component: () => import(`@/modules/hotel/Dashboard/HotelDashboard.vue`),
      },

      // #Home Stay setting
        {
            path: "/HomeStayDashboard",
            name: "HotelStayDashboard",
            meta: { requireAuth: true },
            component: () => import(`@/modules/home_stay/Dashboard/HotelDashboard.vue`),
        },

      {
        path: "/HomeStay",
        name: "HomeStayInformation",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/home_stay/HotelCustomerInformation/List.vue`),
      },

      {
        path: "/homestayReport",
        name: "HomeStayInformationReport",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/home_stay/HotelCustomerInformation/JavaScript.vue`),
      },

      {
        path: "/homestay/homestayDetails/:homestayID",
        name: "HomeStayDetails",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/home_stay/HotelCustomerInformation/HomeStayDetail.vue`
          ),
      },
      {
        path: "/homeStayEmployeeList",
        name: "HomeStayEmployeeInformation",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/home_stay/EmployeeInformation/List.vue`),
      },
      {
        path: "/HomeStay/create",
        name: "HomeStayCreate",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/home_stay/HotelCustomerInformation/create.vue`),
      },
      {
        path: "/HomeStay/edit",
        name: "HomeStayEdit",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/home_stay/HotelCustomerInformation/edit.vue`),
      },
      {
        path: "/HomeStay/details",
        name: "HomeStayDetail",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/home_stay/HotelCustomerInformation/Details.vue`),
      },
      {
        path: "/HomeStay/room",
        name: "HomeStayRoom",
        meta: { requireAuth: true },
        component: () => import(`@/modules/home_stay/HotelRoom/List.vue`),
      },

      {
        path: "/HomeStaySettings",
        name: "HomeStaySettings",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/home_stay/HotelRoom/RoomDashboard.vue`),
      },
      {
        path: "/HomeStayRoomSetting",
        name: "HomeStaySetting",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/home_stay/HotelRoom/HotelRoomSetting/List.vue`),
      },

      {
        path: "/HomeStayHouseSettings",
        name: "HomeStayHouseSettings",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/home_stay/HomestayHouse/HomestayHouseList.vue`),
        },
        {
            path: "/HomeStayHouseListSettings",
            name: "HomeStayHouseListSettings",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/home_stay/HomestayHouse/HomestayHouseListSettings.vue`),
        },
      {
        path: "/HomeStayHouseSettings/CreateHouse",
        name: "HomeStayHouseSettingsCreateHpuse",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/home_stay/HomestayHouse/CreateHouse.vue`),
      },
      {
        path: "/HomeStayHouseSettings/EditHouse",
          name: "HomeStayHouseSettingsEditHouse",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/home_stay/HomestayHouse/EditHouse.vue`),
      },

     // #Special Dish
        {
            path: "/HomeStaySpecialDishSettings",
            name: "HomeStaySpecialDishSettings",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/home_stay/HotelRoom/SpecialDish/SpecialDishList.vue`),
        },
        {
            path: "/HomeStayHouseSettings/CreateDish",
            name: "HomeStaySpecialDishSettingsCreateDish",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/home_stay/HotelRoom/SpecialDish/CreateDish.vue`),
        },
        {
            path: "/HomeStayHouseSettings/EditDish",
            name: "HomeStaySpecialDishSettingsEditDish",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/home_stay/HotelRoom/SpecialDish/EditDish.vue`),
        },

        // # Package settings
      {
        path: "/HomeStayPackageSetting",
        name: "HomeStayPackageSetting",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/home_stay/HotelRoom/Package/List.vue`),
      },
      {
        path: "/HomeStayServiceSetting",
        name: "HomeStayServiceSetting",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/home_stay/HotelRoom/Service/List.vue`),
      },
      {
        path: "/HomeStayCategorySetting",
        name: "HomeStayCategorySetting",
        meta: { requireAuth: true },
        component: () => import(`@/modules/home_stay/HotelCategory/List.vue`),
      },
      {
        path: "/HomeStayaAvailableRoom",
        name: "HomeStayaAvailableRoom",
        meta: { requireAuth: true },
        component: () => import(`@/modules/home_stay/HotelRoom/Available.vue`),
      },
      {
        path: "/HomeStayDashboard",
        name: "HomestayDashboard",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/home_stay/Dashboard/HotelDashboard.vue`),
      },

      // #Adventure
      {
        path: "/AdventureDashboard",
        name: "AdventureDashboard",
        meta: { requireAuth: true },
        component: () => import(`@/modules/adventure/Dashboard/Dashboard.vue`),
      },
      {
        path: "/AdventureCustomerList",
        name: "AdventureCustomerList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/adventure/Customer/CustomerList.vue`),
        },
        {
        path: "/AdventureEmployeeList",
            name: "AdventureEmployeeList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/adventure/EmployeeInformation/List.vue`),
        },
      {
        path: "/AdventureType",
        name: "AdventureType",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/adventure/Settings/Type/List.vue`),
      },
      {
        path: "/AdventureService",
        name: "AdventureService",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/adventure/Settings/Service/List.vue`),
      },
      {
        path: "/AdventureSettings",
        name: "AdventureSettings",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/adventure/Settings/Dashboard/Settings.vue`),
      },
      {
        path: "/AdventureDestination",
        name: "AdventureDestination",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/adventure/Settings/AirDestination/List.vue`),
      },
      {
        path: "/AdventureDetail",
        name: "AdventureDetail",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/adventure/Settings/Details/List.vue`),
      },
      {
        path: "/AdventurePackageSetting",
        name: "AdventurePackageSetting",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/adventure/Settings/Package/List.vue`),
      },

      // #Museum
      {
        path: "/MuseumDashboard",
        name: "MuseumDashboard",
        meta: { requireAuth: true },
        component: () => import(`@/modules/museum/Dashboard/Dashboard.vue`),
      },
      {
        path: "/MuseumCustomerList",
        name: "MuseumCustomerList",
        meta: { requireAuth: true },
        component: () => import(`@/modules/museum/Customer/CustomerList.vue`),
        },
        {
        path: "/MuseumEmployeeList",
            name: "MuseumEmployeeList",
        meta: { requireAuth: true },
            component: () => import(`@/modules/museum/EmployeeInformation/List.vue`),
      },
      {
        path: "/MuseumService",
        name: "MuseumService",
        meta: { requireAuth: true },
        component: () => import(`@/modules/museum/Settings/Service/List.vue`),
      },
      {
        path: "/MuseumSettings",
        name: "MuseumSettings",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/museum/Settings/Dashboard/Settings.vue`),
      },

      // #Pilgrimage
      {
        path: "/PilgrimageDashboard",
        name: "PilgrimageDashboard",
        meta: { requireAuth: true },
        component: () => import(`@/modules/pilgrimage/Dashboard/Dashboard.vue`),
      },
      {
        path: "/PilgrimageCustomerList",
        name: "PilgrimageCustomerList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/pilgrimage/Customer/CustomerList.vue`),
        },
      {
        path: "/PilgrimageEmployeeList",
        name: "PilgrimageEmployeeList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/pilgrimage/EmployeeInformation/List.vue`),
      },
      {
        path: "/PilgrimageService",
        name: "PilgrimageService",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/pilgrimage/Settings/Service/List.vue`),
      },
      {
        path: "/PilgrimageSettings",
        name: "PilgrimageSettings",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/pilgrimage/Settings/Dashboard/Settings.vue`),
      },

      // #TouristPolice
      {
        path: "/TouristPoliceDashboard",
        name: "TouristPoliceDashboard",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/tourist_police/Dashboard/Dashboard.vue`),
      },
      {
        path: "/TouristPoliceCustomerList",
        name: "TouristPoliceCustomerList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/tourist_police/Customer/CustomerList.vue`),
      },
      {
        path: "/TouristPoliceService",
        name: "TouristPoliceService",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/tourist_police/Settings/Service/List.vue`),
      },
      {
        path: "/TouristPoliceSettings",
        name: "TouristPoliceSettings",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/tourist_police/Settings/Dashboard/Settings.vue`),
      },

      // #NationalPark
      {
        path: "/NationalParkDashboard",
        name: "NationalParkDashboard",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/national_park/Dashboard/Dashboard.vue`),
      },
      {
        path: "/NationalParkCustomerList",
        name: "NationalParkCustomerList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/national_park/Customer/CustomerList.vue`),
      },
      {
        path: "/NationalParkService",
        name: "NationalParkService",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/national_park/Settings/Service/List.vue`),
      },
      {
        path: "/NationalParkSettings",
        name: "NationalParkSettings",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/national_park/Settings/Dashboard/Settings.vue`),
      },
      {
          path: "/NationalParkEmployeeList",
          name: "NationalParkEmployeeInformation",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/national_park/EmployeeInformation/List.vue`),
      },
      // #MoneyExchange
      {
        path: "/MoneyExchangeDashboard",
        name: "MoneyExchangeDashboard",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/money_exchange/Dashboard/Dashboard.vue`),
      },
      {
        path: "/MoneyExchangeCustomerList",
        name: "MoneyExchangeCustomerList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/money_exchange/Customer/CustomerList.vue`),
      },
      {
        path: "/MoneyExchangeService",
        name: "MoneyExchangeService",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/money_exchange/Settings/Service/Service.vue`),
      },
      {
        path: "/MoneyExchangeSettings",
        name: "MoneyExchangeSettings",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/money_exchange/Settings/Dashboard/Settings.vue`),
      },

      // #NRBExchange
      {
        path: "/Income-GenerationDashboard",
        name: "Income-GenerationDashboard",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/nrb_exchange/Dashboard/Dashboard.vue`),
      },
      {
        path: "/Income-GenerationCustomerList",
        name: "Income-GenerationCustomerList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/nrb_exchange/Customer/CustomerList.vue`),
      },
      {
        path: "/IncomeGenerationList",
        name: "IncomeGenerationList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/nrb_exchange/IncomeGeneration/List.vue`),
        },
        {
        path: "/ForeignExchangeList",
        name: "ForeignExchangeList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/nrb_exchange/ForeignExchange/List.vue`),
      },
      {
        path: "/Income-GenerationService",
        name: "Income-GenerationService",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/nrb_exchange/Settings/Service/Service.vue`),
      },
      {
        path: "/Income-GenerationSectorSetup",
        name: "Income-GenerationSectorSetup",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/nrb_exchange/Settings/SectorSetup/List.vue`),
      },
      {
        path: "/Income-GenerationSettings",
        name: "Income-GenerationSettings",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/nrb_exchange/Settings/Dashboard/Settings.vue`),
      },
      {
        path: "/IncomeGenerationEmployee",
        name: "NRBEmployeeInformation",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/nrb_exchange/EmployeeInformation/List.vue`),
        },

        // #Central-Bureau
        {
            path: "/TourismEconomicIndicatorDashboard",
            name: "TourismEconomicIndicatorDashboard",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/central_bureau/Dashboard/Dashboard.vue`),
        },
        {
            path: "/CentralBureauList",
            name: "CentralBureauList",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/central_bureau/CentralBureau/List.vue`),
        },
        {
            path: "/Central-BureauService",
            name: "Central-BureauService",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/central_bureau/Settings/Service/Service.vue`),
        },
        {
            path: "/Central-BureauSettings",
            name: "Central-BureauSettings",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/central_bureau/Settings/Dashboard/Settings.vue`),
        },
        {
            path: "/CentralBureauEmployee",
            name: "CentralBureauEmployeeInformation",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/central_bureau/EmployeeInformation/List.vue`),
        },
        // Nepal Mountaineering Association
        {
            path: "/NepalMountaineeringAssociationDashboard",
            name: "NMADashboard",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/NepalMountaineeringAssociation/Dashboard/Dashboard.vue`),
        },
        {
            path: "/NMAList",
            name: "NMAList",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/NepalMountaineeringAssociation/NMA/List.vue`),
        },
        {
            path: "/NMAService",
            name: "NMAService",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/NepalMountaineeringAssociation/Settings/Service/Service.vue`),
        },
        {
            path: "/NMASettings",
            name: "NMASettings",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/NepalMountaineeringAssociation/Settings/Dashboard/Settings.vue`),
        },
        {
            path: "/NMAEmployee",
            name: "NMAEmployeeInformation",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/NepalMountaineeringAssociation/EmployeeInformation/List.vue`),
        },
      // #TravelTour
      {
        path: "/TravelTourDashboard",
        name: "TravelTourDashboard",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/travel_tour/Dashboard/Dashboard.vue`),
      },
      {
        path: "/TravelTourCustomerList",
        name: "TravelTourCustomerList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/travel_tour/Customer/CustomerList.vue`),
      },
      {
        path: "/TravelTourService",
        name: "TravelTourService",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/travel_tour/Settings/Service/List.vue`),
      },
      {
        path: "/TravelTourSettings",
        name: "TravelTourSettings",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/travel_tour/Settings/Dashboard/Settings.vue`),
      },
      {
        path: "/TravelTourPackageSetting",
        name: "TravelTourPackageSetting",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/travel_tour/Settings/Package/List.vue`),
      },

      // #Transportation
      {
        path: "/Tourist-VehicleDashboard",
        name: "TransportationDashboard",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/transportation/Dashboard/Dashboard.vue`),
      },
      {
        path: "/Tourist-VehicleSettings",
        name: "TransportationSettings",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/transportation/Settings/Dashboard/Settings.vue`),
      },
      {
        path: "/Tourist-VehicleDetails",
        name: "TransportationDetails",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/transportation/Settings/Details/List.vue`),
      },
      {
        path: "/Tourist-VehicleDestinations",
        name: "TransportationDestinations",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/transportation/Settings/Destination/List.vue`),
      },
      {
        path: "/Tourist-VehicleCustomerList",
        name: "TransportationCustomerList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/transportation/Customer/CustomerList.vue`),
      },
      {
        path: "/Tourist-VehiclePackageList",
        name: "TransportationPackageList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/transportation/Settings/Package/List.vue`),
      },

      // #Trekking
      {
        path: "/TrekkingDashboard",
        name: "TrekkingDashboard",
        meta: { requireAuth: true },
        component: () => import(`@/modules/trekking/Dashboard/Dashboard.vue`),
      },
      {
        path: "/TrekkingSettings",
        name: "TrekkingSettings",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/trekking/Settings/Dashboard/Settings.vue`),
      },
      {
        path: "/TrekkingCustomerList",
        name: "TrekkingCustomerList",
        meta: { requireAuth: true },
        component: () => import(`@/modules/trekking/Customer/CustomerList.vue`),
      },
      {
        path: "/trekkingEmployeeList",
        name: "TrekkingEmployeeInformation",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/trekking/EmployeeInformation/List.vue`),
      },
      {
        path: "/TrekkingDestinations",
        name: "TrekkingDestinations",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/trekking/Settings/Destination/List.vue`),
      },
      {
        path: "/TrekkingServices",
        name: "TrekkingServices",
        meta: { requireAuth: true },
        component: () => import(`@/modules/trekking/Settings/Service/List.vue`),
      },
      {
        path: "/TrekkingPackages",
        name: "TrekkingPackage",
        meta: { requireAuth: true },
        component: () => import(`@/modules/trekking/Settings/Package/List.vue`),
      },
      {
        path: "/TrekkingActivations",
        name: "TrekkingActivation",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/trekking/Settings/Activation/List.vue`),
      },

      // #TourPackageBusiness
      {
        path: "/TourPackageBusinessDashboard",
        name: "TourPackageBusinessDashboard",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/tour_package_business/Dashboard/Dashboard.vue`),
      },
      {
        path: "/TourPackageBusinessSettings",
        name: "TourPackageBusinessSettings",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/tour_package_business/Settings/Dashboard/Settings.vue`
          ),
      },
      {
        path: "/TourPackageBusinessCustomerList",
        name: "TourPackageBusinessCustomerList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/tour_package_business/Customer/CustomerList.vue`),
      },
      {
        path: "/TourPackageBusinessDestinations",
        name: "TourPackageBusinessDestinations",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/tour_package_business/Settings/Destination/List.vue`
          ),
      },
      {
        path: "/TourPackageBusinessServices",
        name: "TourPackageBusinessServices",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/tour_package_business/Settings/Service/List.vue`),
      },
      {
        path: "/TourPackageBusinessDurations",
        name: "TourPackageBusinessDuration",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/tour_package_business/Settings/Duration/List.vue`),
      },
      {
        path: "/TourPackageBusinessPackages",
        name: "TourPackageBusinessPackage",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/tour_package_business/Settings/Package/List.vue`),
      },
      {
        path: "/TourPackageBusinessActivations",
        name: "TourPackageBusinessActivation",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/tour_package_business/Settings/Activation/List.vue`
          ),
      },

      // #TravelTrekkingGuide
      {
        path: "/TravelTrekkingGuideDashboard",
        name: "TravelTrekkingGuideDashboard",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/travel_trekking_guide/Dashboard/Dashboard.vue`),
      },
      {
        path: "/TravelTrekkingGuideSettings",
        name: "TravelTrekkingGuideSettings",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/travel_trekking_guide/Settings/Dashboard/Settings.vue`
          ),
      },
      {
        path: "/TravelTrekkingGuideCustomerList",
        name: "TravelTrekkingGuideCustomerList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/travel_trekking_guide/Customer/CustomerList.vue`),
      },
      {
        path: "/TravelTrekkingGuideDestinations",
        name: "TravelTrekkingGuideDestinations",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/travel_trekking_guide/Settings/Destination/List.vue`
          ),
      },
      {
        path: "/TravelTrekkingGuideServices",
        name: "TravelTrekkingGuideServices",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/travel_trekking_guide/Settings/Service/List.vue`),
      },
      {
        path: "/TravelTrekkingGuideDurations",
        name: "TravelTrekkingGuideDuration",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/travel_trekking_guide/Settings/Duration/List.vue`),
      },

      {
        path: "/TrekkingDurations",
        name: "TrekkingDuration",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/trekking/Settings/Duration/List.vue`),
      },
      {
        path: "/TravelTrekkingGuidePackages",
        name: "TravelTrekkingGuidePackage",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/travel_trekking_guide/Settings/Package/List.vue`),
      },
      {
        path: "/TravelTrekkingGuideActivations",
        name: "TravelTrekkingGuideActivations",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/travel_trekking_guide/Settings/Activation/List.vue`
          ),
      },

      // #Academic
      {
        path: "/AcademicDashboard",
        name: "AcademicDashboard",
        meta: { requireAuth: true },
        component: () => import(`@/modules/academic/Dashboard/Dashboard.vue`),
      },
      {
        path: "/AcademicsList",
        name: "AcademicsList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/academic/Academic/AcademicsList.vue`),
      },
      {
        path: "/Academics/:slug/pendingList",
        name: "AcademicPendingList",
        meta: { requireAuth: true },
        component: () => import(`@/modules/academic/Pending/PendingList.vue`),
      },
      {
        path: "/AcademicSettings",
        name: "AcademicSettings",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/academic/Settings/Dashboard/Settings.vue`),
      },
      {
        path: "/Academic",
        name: "Academic",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/academic/Settings/Academic/List.vue`),
      },
      {
        path: "/Internship",
        name: "Internship",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/academic/Settings/Internship/List.vue`),
      },
      {
        path: "/InternshipsList",
        name: "InternshipsList",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/academic/Internship/InternshipList.vue`),
      },
      {
        path: "/TrainingsList",
        name: "TrainingsList",
        meta: { requireAuth: true },
        component: () => import(`@/modules/academic/Training/TrainingList.vue`),
      },
      {
        path: "/Training",
        name: "Training",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/academic/Settings/Training/List.vue`),
      },
      {
          path: "/AcademicEmployee",
        name: "AcademicEmployeeInformation",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/academic/EmployeeInformation/List.vue`),
      },
      //#Airlines
      {
        path: "/AirlinesDashboard",
        name: "AirlinesDashboard",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/airport/Dashboard/AirlinesDashboard.vue`),
      },

      //#Airport
      {
        path: "/AirportMainDashboard",
        name: "AirportMainDashboard",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/dashboard/AirlinesDivisionDashboard.vue`),
      },
      {
        path: "/AirportDashboard",
        name: "AirportDashboard",
        meta: { requireAuth: true },
        component: () => import(`@/modules/airport/Dashboard/Dashboard.vue`),
      },
      {
        path: "/AirportDetailDashboard/:airportID",
        name: "AirportDetailDashboard",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/airport/Dashboard/AirportDetail.vue`),
      },
      {
        path: "/AirlinesDetailDashboard/:airlinesID",
        name: "AirlinesDetailDashboard",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/airport/Dashboard/AirlinesDetails.vue`),
      },
      {
        path: "/OldAirportDashboard",
        name: "OldAirportDashboard",
        meta: { requireAuth: true },
        component: () => import(`@/modules/airport/Dashboard/OldDashboard.vue`),
      },
      {
        path: "/Airport/DomesticPassenger",
        name: "DomesticPassenger",
        meta: { requireAuth: true },
        component: () => import(`@/modules/airport/DomesticPassenger/List.vue`),
      },
      {
        path: "/Airport/Details",
        name: "AirportDetails",
        meta: { requireAuth: true },
        component: () => import(`@/modules/airport/Details/Detail.vue`),
      },
      {
        path: "/AirportSettings",
        name: "AirportSettings",
        meta: { requireAuth: true },
        component: () => import(`@/modules/airport/Details/List.vue`),
      },

      // #Report setting
      {
        path: "/report",
        name: "ReportSetting",
        meta: { requireAuth: true },
        component: () => import(`@/modules/report/Index.vue`),
      },

      //Survey
      {
        path: "/SurveyDashboard",
        name: "SurveyDashboard",
        meta: { requireAuth: true },
        component: () => import(`@/modules/survey/Dashboard/Dashboard.vue`),
      },
      {
        path: "/Survey/Provinces",
        name: "SurveyProvinceCategories",
        meta: { requireAuth: true },
        component: () => import(`@/modules/survey/Dashboard/ProvinceList.vue`),
      },
      {
        path: "/Survey/Province/:provinceId/Divisions",
        name: "SurveyProvinceDivisions",
        meta: { requireAuth: true },
        component: () => import(`@/modules/survey/Dashboard/DivisionList.vue`),
      },
      {
        path: "/Survey/Province/:provinceId/Division/:divisionId/Projects",
        name: "SurveyProvinceDivisionProjects",
        meta: { requireAuth: true },
        component: () => import(`@/modules/survey/Projects/ProjectList.vue`),
      },
      {
        path:
          "/Survey/Province/:provinceId/Division/:divisionId/Project/:projectId/SurveyQuestionLists",
        name: "SurveyQuestionLists",
        meta: { requireAuth: true },
        component: () => import(`@/modules/survey/Questions/QuestionList.vue`),
      },
      {
        path: "/BranchSurveyProjects",
        name: "BranchSurveyProjects",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/survey/BranchProjects/BranchProjectList.vue`),
      },
      {
        path: "project/:projectId/SurveySystem",
        name: "SurveySystem",
        meta: { requireAuth: true },
        component: () => import(`@/modules/survey/Answers/QuestionList.vue`),
        },
        {
        path: "/IndividualHomestayReport",
        name: "IndividualHomestayReport",
        meta: { requireAuth: true },
        component: () =>
                import(`@/modules/IndividualHomestayReport/Dashboard/Dashboard.vue`),
        },
        {
            path: "/IndividualHomestayReport/customerVisit",
            name: "IndividualHomestayReportcustomerVisit",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/IndividualHomestayReport/customerVisit/customerVisit.vue`),
        },
        {
            path: "/IndividualHomestayReport/CustomerVisitHomestay",
            name: "IndividualHomestayReportCustomerVisitHomestay",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/IndividualHomestayReport/customerVisit/CustomerVisitHomestay.vue`),
        },
        {
            path: "/IndividualHomestayReport/occupiedHomestayHouse",
            name: "IndividualHomestayReportoccupiedHomestayHouse",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/IndividualHomestayReport/occupiedHomestayHouse/occupiedHomestayHouse.vue`),
        },
        {
            path: "/IndividualHomestayReport/customerVisitCountryWise",
            name: "IndividualHomestayReportcustomerVisitCountryWise",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/IndividualHomestayReport/customerVisitCountryWise/customerVisitCountryWise.vue`),
        },
      {
        path: "/reportModules",
        name: "reportModules",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/reportModules/Dashboard/Dashboard.vue`),
      },
      {
        path: "/reportModules/reportTrekking",
        name: "reportTrekkingModules",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/reportModules/report/Trekking/Dashboard.vue`),
      },
      {
        path: "/reportModules/reportTrekking/saarc",
        name: "saarcReportTrekkingModules",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/Trekking/HistorySaarc/HistoryList.vue`
          ),
      },
      {
        path: "/reportModules/reportTrekking/reportHeaderSetting",
        name: "trekkingReportHeaderSetting",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/Trekking/ReportColumn/TrekkingList.vue`
          ),
      },
      {
        path: "/reportModules/reportTrekking/monthly-yearly",
        name: "monthlyYearlyReportTrekkingModules",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/Trekking/History/HistoryList.vue`
          ),
        },
        {
            path: "/reportModules/reportTrekking/restrictedAreas",
            name: "RestrictedAreasReportTrekkingModules",
            meta: { requireAuth: true },
            component: () =>
                import(
                    `@/modules/reportModules/report/Trekking/History/RestrictedAreas.vue`
                ),
        },
        {
            path: "/reportModules/reportTrekking/restrictedAreasReport",
            name: "RestrictedAreasFilterReportTrekkingModules",
            meta: { requireAuth: true },
            component: () =>
                import(
                    `@/modules/reportModules/report/Trekking/History/RestrictedAreasReport.vue`
                ),
        },
      {
        path: "/reportModules/reportDashboardHomeStay",
          name: "reportHomeStayreportDashboardHomeStay",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/reportModules/report/HomeStay/Dashboard.vue`),
      },
      {
        path: "/reportModules/Report/monthly-yearly",
          name: "reportHomeStayMonthlyYearly",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/HomeStay/HomeStayReport/Report.vue`
          ),
      },
      {
        path: "/reportModules/CustomerReport/monthly-yearly",
          name: "reportHomeStayCustomerReport",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/HomeStay/CustomerReport/Report.vue`
          ),
        },
        {
            path: "/reportModules/EmployeeReport/monthly-yearly",
          name: "reportHomeStayEmployeeReport",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/HomeStay/HomestayEmployee/Report.vue`
          ),
      },

      {
        path: "/reportModules/reportTouristVehicle",
        name: "reportTouristVehicle",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/TouristVehicle/TouristVehicleReport/Report.vue`
          ),
      },
      {
        path: "/reportModules/reportAviation",
        name: "reportAviation",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/reportModules/report/Aviation/Dashboard.vue`),
      },
      {
        path: "/reportModules/reportAviation/airlineCategory",
          name: "reportAviationairlineCategory",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/Aviation/AviationReport/Report.vue`
          ),
      },
      {
        path: "/reportModules/reportAviation/airlineDetail",
          name: "reportAviationairlineDetail",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/Aviation/AirDetailsReport/Report.vue`
          ),
      },

      //----------------------------------------------------------------------------------------//

      //report Academic
      {
        path: "/reportModules/reportAcademic",
        name: "reportAcademic",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/Academic/AcademicReport/Report.vue`
          ),
      },
      {
        path: "/reportModules/reportAcademic/monthly-yearly",
          name: "reportAcademicMonthlyYearly",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/Academic/AcademicReport/YearlyReport.vue`
          ),
      },
      {
        path: "/reportModules/reportAcademic/private-public-instituteReport",
          name: "reportAcademicPrivatePublicInstituteReport",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/Academic/AcademicReport/PublicPrivateInstituteReport.vue`
          ),
      },

      //----------------------------------------------------------------------------------------//

      {
        path: "/reportModules/reportIncomeGenerationDashboard",
        name: "reportIncomeGeneration",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/IncomeGeneration/Dashboard.vue`
          ),
        },
        {
        path: "/reportModules/reportIncomeGeneration",
        name: "reportIncomeGeneration",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/IncomeGeneration/IncomeGenerationReport/Report.vue`
          ),
        },
        {
            path: "/reportModules/reportNrb",
            name: "reportNrb",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/reportModules/report/Nrb/Dashboard.vue`),
        },
        {
            path: "/reportModules/reportCentralBureau",
            name: "reportCentralBureau",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/reportModules/report/CentralBureau/Dashboard.vue`),
        },
      {
        path: "/reportModules/reportAirport",
        name: "reportAirport",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/reportModules/report/Airport/Dashboard.vue`),
        },
        {
        path: "/reportModules/reportNMA",
            name: "reportNMA",
        meta: { requireAuth: true },
        component: () =>
            import(`@/modules/reportModules/report/NepalMountaineeringAssociation/Dashboard.vue`),
      },
      {
        path: "/reportModules/reportAirport/reportDomesticMovement",
        name: "reportDomesticMovement",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/Airport/DomesticMovementReport/Report.vue`
          ),
      },
      {
        path: "/reportModules/reportAirport/airportList",
        name: "airportList",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/Airport/AirportReport/Report.vue`
          ),
      },
      {
        path: "/reportModules/reportMountaineering",
        name: "reportMountaineering",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/Mountain/MountainReport/Report.vue`
          ),
      },
      {
        path: "/reportModules/reportPilgrimage",
        name: "reportPilgrimageModules",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/reportModules/report/Pilgrimage/Dashboard.vue`),
      },
      {
        path: "/reportModules/reportPilgrimage/monthly-yearly",
        name: "monthlyYearlyReportPilgrimageModules",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/Pilgrimage/PilgrimageReport/Report.vue`
          ),
      },
      {
        path: "/reportModules/reportTourist-Arrival",
        name: "reportTourist-ArrivalModules",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/reportModules/report/Immigration/Dashboard.vue`),
      },

      {
        path:
          "/reportModules/reportTourist-Arrival/InternationalPassengerMovementReport",
        name: "InternationalPassengerMovementReport",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/Immigration/InternationalPassengerMovementReport.vue`
          ),
      },
      {
        path: "/reportModules/reportTourist-Arrival/national-international",
        name: "nationalityReportTourist-ArrivalModules",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/Immigration/NationalityReport/NationalityReportList.vue`
          ),
      },
      {
        path: "/reportModules/reportTourist-Arrival/report-arrival",
        name: "arrivalReportTourist-ArrivalModules",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/Immigration/ImmigrationReport/Report.vue`
          ),
      },
      {
        path: "/reportModules/reportTourist-Arrival/AverageLengthOfStay",
        name: "AverageLengthOfStay",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/Immigration/AverageLengthOfStay/Report.vue`
          ),
      },
      {
        path: "/reportModules/reportTourist-Arrival/PassengerInfoReport",
          name: "AveragePassengerInfoReport",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/Immigration/PassengerInformationReport/PassengerInfoReport.vue`
          ),
      },

      {
        path: "/reportModules/reportNationalPark",
        name: "reportNationalParkModules",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/reportModules/report/NationalPark/Dashboard.vue`),
      },
      {
        path: "/reportModules/reportNationalPark/monthly-yearly",
        name: "monthlyYearlyReportNationalParkModules",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/NationalPark/NationalParkReport/Report.vue`
          ),
      },
      {
        path: "/reportModules/reportHotel",
        name: "reportHotelModules",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/reportModules/report/Hotel/Dashboard.vue`),
      },
      {
        path: "/reportModules/reportHotel/monthly-yearly",
        name: "monthlyYearlyReportHotelModules",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/reportModules/report/Hotel/HotelReport/Report.vue`),
        },
        {
            path: "/reportModules/HotelEmployeeReport/monthly-yearly",
        name: "monthlyYearlyEmployeeReportHotelModules",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/reportModules/report/Hotel/HotelEmployee/Report.vue`),
      },
      {
        path: "/reportModules/HotelCustomerReport/monthly-yearly",
          name: "monthlyYearlyCustomerReportHotelModules",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/Hotel/CustomerInfoReport/CustomerReport.vue`
          ),
      },
      {
        path: "/reportModules/reportTouristPolice",
        name: "reportTouristPoliceModules",
        meta: { requireAuth: true },
        component: () =>
          import(`@/modules/reportModules/report/TouristPolice/Dashboard.vue`),
      },
      {
        path: "/reportModules/reportTouristPolice/monthly-yearly",
        name: "monthlyYearlyReportTouristPoliceModules",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/TouristPolice/TouristPoliceReport/Report.vue`
          ),
        },
        {
            path: "/reportModules/reportDot",
            name: "reportDot",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/reportModules/report/Dot/Dashboard.vue`),
        },
        {
            path: "/immigrationApiCheck",
            name: "immigrationApi",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/immigration/ImmigrationApiList.vue`),
        },
        {
            path: "/ImmigrationRawDataImport",
            name: "ImmigrationRawDataImport",
            meta: { requireAuth: true },
            component: () =>
                import(`@/modules/immigration/ImmigrationRawDataImport/List.vue`),
        },
        {
            path: "/reportModules/reportDot/dotHotel",
            name: "dotHotel",
            meta: { requireAuth: true },
            component: () =>
                import(
                    `@/modules/reportModules/report/Dot/DotHotel/Dashboard.vue`
                ),
        },
        {
            path: "/reportModules/reportDot/dotHotel/registered",
            name: "registered",
            meta: { requireAuth: true },
            component: () =>
                import(
                    `@/modules/reportModules/report/Dot/DotHotel/Registered/List.vue`
                ),
        },
        {
            path: "/reportModules/reportDot/dotHotel/renewed",
            name: "renewed",
            meta: { requireAuth: true },
            component: () =>
                import(
                    `@/modules/reportModules/report/Dot/DotHotel/Renewed/List.vue`
                ),
        },
        {
            path: "/reportModules/reportDot/dotMountain",
            name: "dotMountain",
            meta: { requireAuth: true },
            component: () =>
                import(
                    `@/modules/reportModules/report/Dot/DotMountain/Report.vue`
                ),
        },
      {
        path: "/privacypolicy",
        name: "privacypolicy",
        meta: { requiresAuth: false },
        component: () => import("@/modules/admin-user/PrivacyPolicy.vue"),
      },
      {
        path: "reportTourist-ArrivalNational",
        name: "reportTourist-ArrivalNational",
        meta: { requireAuth: true },
        component: () =>
          import(
            `@/modules/reportModules/report/Immigration/NationalityReportList.vue`
          ),
      },
      // {
      //     path: 'reportImmigrationVisitorsArrivalsList',
      //     name: 'reportImmigrationNational',
      //     meta: { requireAuth: true },
      //     component: () => import(`@/modules/reportModules/report/Immigration/NationalityReportList.vue`)
      // },

      {
        path: "track",
        name: "trackingModules",
        meta: { requireAuth: true },
        component: () => import(`@/modules/tracking/Index.vue`),
      },
    ],
  },
  {
    path: "/homepage",
    meta: { requiresAuth: false },
    component: () =>
      import(/* webpackChunkName: "routes" */ `@/views/SigninView.vue`),
    // redirect if already signed in
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/authenticated"]) {
        next("/");
      } else {
        next();
      }
    },
    children: [
      {
        path: "",
        component: () => import(`@/public_pages/Signin.vue`),
        beforeEnter: (to, from, next) => {
          if (store.getters["auth/authenticated"]) {
            next("/");
          } else {
            next();
          }
        },
      },
    ],
  },
  {
    path: "/resetPassword/:userID",
    meta: { requiresAuth: false },
    component: () =>
      import(/* webpackChunkName: "routes" */ `@/components/PasswordReset.vue`),
  },
];
